<template>
    <div class="pt-3 mb-3">
        {{title}}
        <div>
            <b-nav class="w-auto bg-white" pills vertical>
                <div v-for="(item, index) in getItems">
                    <!-- text -->
                    <b-nav-text
                        v-if="item.type === 'text'"
                        class="pl-2 font-weight-bold text-secondary"
                        v-html="item.text"
                    ></b-nav-text>
                    <!-- divider -->
                    <hr v-if="item.type === 'divider'" class="ml-2 mr-3">
                    <!-- item -->
                    <b-nav-item v-if="item.type === 'item'" :active="item.active" :disabled="item.disabled" :to="item.path">
                        <span v-if="numbering">{{index + 1}}.</span>
                        {{item.text}}
                    </b-nav-item>
                </div>
            </b-nav>
        </div>
    </div>
</template>

<script>
export default {
    name: "SiderMenuItems",
    components: {},
    props: {
        title: null,
        items: {
            type: Array
        },
        sorted: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        numbering: {
            type: Boolean,
            default: function () {
                return false
            }
        },
    },
    data() {
        return {}
    },
    mounted() {
        this.setActive();
    },
    methods: {
        setActive() {
            let route = this.$router.currentRoute.path;
            this.items.forEach((item, index) => {
                item.active = item.path === route ? true : false;
            });
        }
    },
    computed: {
        getItems() {
            let items = this.items;
            // <-- sorted -->
            if (this.sorted) {
                items = this.items.sort((t1, t2) => t1.text < t2.text ? -1 : 1)
            }
            // <--  -->
            return items;
        }
    },
    filters: {},
    watch: {
        $route(to, from) {
            this.setActive();
        }
    }
}
</script>

<style scoped>

</style>
