<template>
    <div>
        <b-row no-gutters>
            <b-col lg="2" md="3" sm="12" xs="12">
                <sider-menu-items :items="menuSiderItems.items"/>
            </b-col>
            <b-col lg="10" md="9" sm="12" xs="12">
                <router-view></router-view>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import SiderMenuItems from "@/components/general/elements/menus/SiderMenuItems";
export default {
    name: "BusinessContent",
    components: {SiderMenuItems},
    props: {},
    data() {
        return {
            menuSiderItems: {
                items: [
                    // <-- business -->
                    {
                        type: 'text',
                        text: this.$t('business.sider_menu.business'),
                    },
                    {
                        type: 'item',
                        text: this.$t('business.sider_menu.b2b_invoice'),
                        path: '/business/b2b_invoices',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('business.sider_menu.b2c_invoice'),
                        path: '/business/b2c_invoices',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('business.sider_menu.acceptance_form'),
                        path: '/business/acceptances',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('business.sider_menu.delivery_receipt'),
                        path: '/business/deliveries',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('business.sider_menu.order_form'),
                        path: '/business/orders',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('business.sider_menu.return_form'),
                        path: '/business/returns',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('business.sider_menu.offer_form'),
                        path: '/business/offers',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('business.sider_menu.travel_order'),
                        path: '/business/travel_orders',
                        disabled: false,
                        active: false,
                    },
                ]
            },
        }
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
